<template>
  <div class="xiaoheHouseKeeper">
    <div class="card">
      <div class="card-t">
        <div class="img"><img :src="detail.picture" alt="" /></div>
        <div class="name">
          <div class="name-t">{{ detail.name }}</div>
          <div class="name-b">
            {{ detail.communityName }}-{{ detail.blockName }}
          </div>
        </div>
        <div class="operation">
          <img @click="toAlink" class="tel_icon" src="./img/tel.png" alt="" />
          <img
            @click="toWechat"
            class="wechat_icon"
            src="./img/wechat.png"
            alt=""
          />
        </div>
      </div>
      <!-- <div class="card-b">
        <div class="tel-l" @click="toAlink">
          <div class="img"><img src="./img/tel.png" alt="" /></div>
          <div class="txt">电话联系</div>
          <div class="arrow"><img src="./img/arrow.png" alt="" /></div>
        </div>
        <div class="center"></div>
        <div class="tel-r" @click="toWechat">
          <div class="img"><img src="./img/wechat.png" alt="" /></div>
          <div class="txt">微信联系</div>
          <div class="arrow"><img src="./img/arrow.png" alt="" /></div>
        </div>
      </div> -->
    </div>
    <div class="main">
      <div class="title">
        <div class="title-r">管家评分（{{ detail.evaluateCount }}次）</div>
        <div class="title-l">
          <div>
            {{ evaluateScoreFormat(detail.evaluateScore) }}
          </div>
          <div class="star_box">
            <div v-for="(item, index) in 5" :key="index">
              <img
                v-if="item <= detail.evaluateScore"
                src="./img/starSelect.png"
              />
              <img v-else src="./img/star.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="commentList">
        <v-list
          :finished="finished"
          @onLoad="onLoad"
          :immediateCheck="false"
          ref="list"
          :finishedText="finishedText"
        >
          <div class="item" v-for="(item, index) in listData" :key="index">
            <div class="item-t">
              <div class="item-tl">
                <div class="headImg">
                  <img
                    :src="
                      item.evaluaterAvatar
                        ? item.evaluaterAvatar
                        : require('@/assets/img/default_avatar.png')
                    "
                    alt=""
                  />
                </div>
                <div class="name">
                  <div class="name-t">{{ item.evaluaterName }}</div>
                  <div class="name-b">
                    <div class="img">
                      <div class="star">
                        <img
                          v-for="(item, index) in star"
                          :key="index"
                          src="./img/star.png"
                          alt=""
                        />
                      </div>
                      <div class="starselect">
                        <img
                          v-for="(oitem, index) in item.starselct"
                          :key="index"
                          src="./img/starSelect.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="txt">{{ isCommentStatusMap[item.score] }}</div>
                  </div>
                </div>
              </div>
              <div class="item-tr">{{ item.evaluateTime }}</div>
            </div>
            <div class="item-b">
              {{ item.commentContent }}
            </div>
          </div>
        </v-list>
        <div class="commentBtn" @click="toComment" v-if="!isCommentDialog">
          发表评论
        </div>
      </div>
    </div>
    <div class="tipsBox" v-if="isTipsDialog" @click="isTipsDialog = false">
      <div class="box">
        <div class="box_t">提示</div>
        <div class="box_c">确认拨打电话：{{ detail.mobile }}吗？</div>
        <div class="box_b">
          <div class="btn_l">取消</div>
          <div @click="toAlink" class="btn_r border-l">确认</div>
        </div>
      </div>
    </div>
    <div class="wechatBox" v-if="isDialog" @click="closeDialog">
      <div class="img" @click.stop="showImg">
        <img :src="detail.wechatQrcode" alt="" />
        <img
          @click.stop="closeDialog"
          class="close_icon"
          src="./img/delete.png"
          alt=""
        />
      </div>
    </div>
    <div class="commentBox" v-if="isCommentDialog">
      <div class="box">
        <div class="box-center">
          <div class="commentBox-t">
            <div class="img"><img :src="detail.picture" alt="" /></div>
            <div class="name">
              <div class="name-t">{{ detail.name }}</div>
              <div class="name-b">
                {{ detail.communityName }}-{{ detail.blockName }}
              </div>
            </div>
          </div>
          <div class="commentBox-c">
            <div class="title">打分</div>
            <div class="img">
              <div class="star">
                <img
                  v-for="(item, index) in star"
                  :key="index"
                  src="./img/star.png"
                  alt=""
                  @click="selectStar(index)"
                />
              </div>
              <div class="starselect">
                <img
                  v-for="(item, index) in starselct"
                  :key="index"
                  src="./img/starSelect.png"
                  alt=""
                  @click="cancelStar(index)"
                />
              </div>
            </div>
          </div>
          <div class="commentBox-b">
            <v-input
              v-model="form.commentContent"
              :maxlength="30"
              placeholder="请填写评价"
              :showWordLimit="true"
              type="textarea"
            ></v-input>
          </div>

          <div
            v-if="form.commentContent && form.score"
            class="surebutton"
            @click="postComment"
          >
            发送
          </div>
          <div v-else class="button">发送</div>
        </div>
        <div class="closeBox" @click="closeBox">
          <img src="./img/delete.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import {
  getButlerListUrl,
  getButlerCommentListURL,
  commentListURL,
} from "./api.js";
import { isCommentStatus, isCommentStatusMap } from "./map.js";
import { toRegister, gloabalCount } from "@/utils/common.js";
import { getHashParam } from "@/utils/utils.js";
var moment = require("moment");
export default {
  name: "xiaoheHouseKeeper",
  data() {
    return {
      isCommentStatusMap,
      isTipsDialog: false,
      isCommentDialog: false,
      finishedText: "没有更多了",
      finished: false,
      form: {
        commentContent: "",
        score: "",
      },
      starselct: [],
      star: [1, 1, 1, 1, 1],
      detail: {},
      isDialog: false,
      listData: [],
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  async mounted() {
    if (await toRegister(this.$route.path, this.$route.query, "明珠管家")) {
      this.getDetail();
    }
  },
  methods: {
    evaluateScoreFormat(num) {
      if (num % 1 == 0) {
        return num + ".0";
      } else {
        return num;
      }
    },
    toComment() {
      this.isCommentDialog = true;
    },
    closeBox() {
      this.isCommentDialog = false;
    },
    cancelStar(index) {
      this.starselct = [];
      for (var i = 0; i <= index; i++) {
        this.starselct.push(1);
      }
      this.form.score = this.starselct.length;
    },
    selectStar(index) {
      this.starselct = [];
      for (var i = 0; i <= index; i++) {
        this.starselct.push(1);
      }
      this.form.score = this.starselct.length;
    },
    onLoad() {
      this.getPartyList();
    },
    toAlink() {
      let _this = this;
      window.location.href = `tel://${_this.detail.mobile}`;
    },
    closeDialog() {
      this.isDialog = false;
    },
    toWechat(item) {
      this.isDialog = true;
    },
    async getDetail() {
      let res = await this.$axios.get(getButlerListUrl, {
        params: {
          houseId: this.roomId,
        },
      });
      if (res.code === 200) {
        this.detail = res.data;
        gloabalCount("", 44, 1);
        this.getPartyList();
      } else {
        setTimeout(() => {
          wx.miniProgram.navigateTo({
            url: `/pages/index/index`,
          });
        }, 2000);
      }
    },
    async postComment() {
      let params = {
        butlerId: this.detail.id,
        commentContent: this.form.commentContent,
        optUser: this.userId,
        score: this.form.score,
      };
      let res = await this.$axios.post(commentListURL, params);
      if (res.code === 200) {
        this.$toast("评价成功");
        this.isCommentDialog = false;
        this.form.commentContent = "";
        this.requestData.curPage = 1;
        this.starselct = [];
        this.listData = [];
        this.getDetail();
      } else {
      }
    },
    async getPartyList() {
      let res = await this.$axios.get(getButlerCommentListURL, {
        params: {
          curPage: this.requestData.curPage++,
          pageSize: this.requestData.pageSize,
          butlerId: this.detail.id,
        },
      });
      if (res.code === 200) {
        if (this.requestData.curPage >= res.data.pages) {
          this.finished = true;
        } else {
          this.finished = false;
          this.$refs.list.loading = false;
        }
        this.finishedText = "没有更多了";
        if (res.data.records.length === 0) {
          this.finishedText = "";
          return;
        }
        res.data.records.forEach((item) => {
          // 如果请求数据不重复，则添加进listData
          item.starselct = [];
          if (item.score) {
            for (var i = 0; i < item.score; i++) {
              item.starselct.push(1);
            }
          }
          this.listData.push(item);
        });
      } else {
      }
    },
  },
};
</script>

<style lang="less" scoped>
.xiaoheHouseKeeper {
  min-height: 100vh;
  padding: 42px 32px;
  .tipsBox {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.5);
    .box {
      width: 568px;
      height: 290px;
      background: #ffffff;
      border-radius: 16px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      .box_t {
        width: 100%;
        font-size: 32px;
        font-weight: 600;
        color: #333333;
        text-align: center;
        padding-top: 50px;
      }
      .box_c {
        width: 100%;
        white-space: nowrap;
        font-size: 30px;
        color: rgba(0, 0, 0, 0.5);
        text-align: center;
        padding-top: 26px;
        box-sizing: border-box;
      }
      .box_b {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 86px;
        border-top: 2px solid rgba(0, 0, 0, 0.1);
        display: flex;
        .btn_l,
        .btn_r {
          flex: 1;
          text-align: center;
          line-height: 86px;
          font-size: 36px;
          color: #333333;
        }
        .border-l {
          border-left: 2px solid rgba(0, 0, 0, 0.1);
          font-weight: 600;
          color: #5e5aff;
        }
      }
    }
  }
  .wechatBox {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.5);

    .img {
      width: 370px;
      position: absolute;
      height: 368px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      .close_icon {
        width: 76px;
        height: 76px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -126px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .card {
    height: 248px;
    background: #ffffff;
    box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
    border-radius: 16px;
    position: relative;
    .card-t {
      display: flex;
      // padding: 50px 48px 0;
      padding-left: 48px;
      padding-right: 46px;
      align-items: center;
      height: 100%;
      .img {
        width: 150px;
        height: 150px;
        margin-right: 36px;
        border-radius: 50%;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .name {
        flex: 1;
        .name-t {
          font-size: 36px;
          font-weight: 600;
          color: #333333;
          line-height: 44px;
        }
        .name-b {
          font-size: 28px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 40px;
          margin-top: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          word-break: break-all;
        }
      }
      .operation {
        height: 100%;
        display: flex;
        align-items: center;
        .tel_icon {
          margin-left: 86px;
          width: 40px;
          height: 52px;
        }
        .wechat_icon {
          margin-left: 36px;
          width: 56px;
          height: 56px;
        }
      }
    }
    .card-b {
      width: 100%;
      height: 96px;
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      border-top: 1px solid rgba(0, 0, 0, 0.06);
      .center {
        width: 1px;
        height: 80px;
        background: rgba(0, 0, 0, 0.06);
      }
      .tel-l,
      .tel-r {
        flex: 1;
        justify-content: center;
        display: flex;
        align-items: center;
        .img {
          width: 48px;
          height: 48px;
          font-size: 0;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .txt {
          font-size: 24px;
          margin: 0 10px;
          font-weight: 400;
          color: #333333;
          line-height: 34px;
        }
        .arrow {
          width: 10px;
          height: 20px;
          font-size: 0;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .main {
    .title {
      padding: 40px 18px 2px;
      justify-items: center;

      .title-l {
        font-size: 66px;
        font-weight: 600;
        color: #5e5aff;
        margin-top: 8px;
        display: flex;
        justify-content: space-between;
        .star_box {
          display: flex;
          align-items: center;
          img {
            margin-left: 18px;
            width: 54px;
            height: 52px;
          }
        }
      }
      .title-r {
        font-size: 36px;
        padding-top: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 40px;
      }
    }
    .commentList {
      padding-bottom: 130px;
      .item {
        padding: 48px 0 42px;
        border-bottom: 2px solid rgba(0, 0, 0, 0.06);
        .item-t {
          display: flex;
          justify-content: space-between;
          margin-bottom: 16px;
          .item-tl {
            display: flex;
            align-items: center;
            .headImg {
              width: 92px;
              height: 92px;
              border-radius: 50%;
              margin-right: 12px;
              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }
            }
            .name-t {
              font-size: 26px;
              font-weight: 400;
              color: #333333;
              line-height: 40px;
            }
            .name-b {
              display: flex;
              align-items: center;
              .img {
                position: relative;
                margin-right: 10px;
                height: 26px;
                .star,
                .starselect {
                  img {
                    width: 28px;
                    height: 26px;
                    margin-right: 8px;
                  }
                }
                .starselect {
                  position: absolute;
                  top: 0;
                  left: 0;
                }
              }
              .txt {
              }
              font-size: 26px;
              font-weight: 400;
              color: #333333;
              line-height: 34px;
            }
          }
          .item-tr {
            font-size: 26px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.25);
            line-height: 40px;
          }
        }
        .item-b {
          font-size: 26px;
          font-weight: 400;
          color: #333333;
          line-height: 36px;
          word-break: break-all;
        }
      }
    }
  }
  .commentBox {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background: rgba(0, 0, 0, 0.5);
    .box {
      width: 656px;
      height: 718px;
      background: #ffffff;
      border-radius: 12px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      .box-center {
        .commentBox-t {
          display: flex;
          padding: 36px 36px 0px 36px;
          align-items: center;
          // border-bottom: 1px solid rgba(0, 0, 0, 0.06);
          .img {
            width: 128px;
            height: 128px;
            margin-right: 22px;
            border-radius: 50%;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
          .name {
            // padding-top: 16px;
            .name-t {
              font-size: 32px;
              font-weight: 600;
              color: #333333;
              line-height: 44px;
            }
            .name-b {
              font-size: 24px;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.5);
              line-height: 40px;
              margin-top: 4px;
            }
          }
        }
        .commentBox-c {
          padding: 38px 36px 26px;
          display: flex;
          align-items: center;
          .title {
            margin-right: 20px;
            font-size: 32px;
            color: #333333;
          }
          .img {
            position: relative;
            margin-right: 10px;
            .star,
            .starselect {
              img {
                width: 46px;
                height: 46px;
                margin-right: 6px;
              }
            }
            .starselect {
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }
        .commentBox-b {
          padding: 0 34px;
          /deep/ .van-field {
            height: 290px;
            border-radius: 12px;
            box-sizing: border-box;
            background: rgba(0, 0, 0, 0.05);
            // opacity: 0.3;
            // border: 2px solid #aaaaaa;
            .van-field__body {
              box-sizing: border-box;
              height: 80%;
              input {
                box-sizing: border-box;
                height: 100%;
                color: rgba(0, 0, 0, 0.25);
              }
            }
          }
        }
      }
      .surebutton,
      .button {
        width: 550px;
        height: 66px;
        background: linear-gradient(180deg, #ececec 0%, #d8d8d8 100%);
        border-radius: 10px;
        text-align: center;
        font-size: 30px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.25);
        line-height: 66px;
        margin: 42px auto;
      }
      .surebutton {
        background: linear-gradient(180deg, #9591ff 0%, #605cff 100%);
        color: #fff;
      }
      .closeBox {
        width: 76px;
        height: 76px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -138px;
        margin: auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .commentBtn {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #9490ff 0%, #5f5bff 100%);
    border-radius: 10px;
    font-size: 30px;
    text-align: center;
    font-weight: 600;
    color: #ffffff;
    line-height: 66px;
    position: fixed;
    bottom: 38px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 999;
  }
}
</style>
